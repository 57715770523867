import { HttpErrorResponse } from '@angular/common/http';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, tap } from 'rxjs';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IResponseMessage } from 'src/app/model/interfaces/response.interface';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { GetStartedGuideService } from 'src/app/services/get-started-guide/get-started-guide.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UtilService } from 'src/app/services/util/util.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { IntercomService } from '@services/intercom/intercom.service';
import { EDataType } from '@model/enums/data-type.enum';
import { ClaimsService } from '@services/claims/claims.service';

@Component({
  selector: 'cogsig-dashboard-page',
  templateUrl: './cogsig-dashboard.component.html',
  styleUrls: ['./cogsig-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CogsigDashboardComponent {
  claimsService = inject(ClaimsService);

  /**
   * Environment cogsig or mailtastic
   */
  isCogSig = environment.isCogSig;

  sectionData$ = this.guideService.sectionGetToKnow$;

  /**
   * Observer used when sent the feedback
   */
  sendFeedbackObserver = {
    next: (result: IResponseMessage) => {
      this.feebackMessage = '';
      void this.alert.defaultInfoMessage(result.message);
    },
    error: (err: HttpErrorResponse) => void this.alert.defaultErrorMessage(err.message)
  };

  feebackMessage = '';

  /**
   * Get Authorization scope values from the token
   */
  authorizationScope$ = this.guideService.authorizationScope$;

  constructor(
    private employeeService: EmployeeService,
    private router: Router,
    private utilService: UtilService,
    public alert: AlertService,
    public guideService: GetStartedGuideService,
    public intercomService: IntercomService,
    public modalService: ModalService,
    public translate: TranslocoService
  ) {}

  /**
   * Send the feedback
   * @returns Observable of the feedback
   */
  sendFeedback(): Observable<IResponseMessage> {
    return this.employeeService.sendFeedback(this.feebackMessage);
  }

  /**
   * Sends the feedback message
   */
  submitFeedback(): void {
    this.sendFeedback().subscribe(this.sendFeedbackObserver);
  }

  /**
   * Opens the modal to play the video
   * @param url - translate tag containing url
   * @param title - translate tag containing title
   */
  async openPlayVideoModal(url: string, title: string, step?: IWizardTabComponent): Promise<void> {
    // Open modal and handle video play within
    const completed = await this.modalService.openPlayVideoModal(url, title);
    if (completed && step) {
      this.guideService
        .updateGuide(step)
        .pipe(tap(() => this.guideService.triggerGuideData()))
        .subscribe();
    }
  }

  /**
   * Returns the percentage of progress made in completing the section steps
   * @param steps - Wizard steps for this section
   * @returns Percentage of complete steps divided by total steps
   */
  calculateProgress(steps: IWizardTabComponent[]): number {
    if (steps.length > 0) {
      const completedStep = steps.filter(({ completed }) => completed === true).length;
      return (completedStep * 100) / steps.length;
    }
    return 0;
  }

  /**
   * Redirect to o365 integration for mailtastic or old angular app for cogsig
   */
  openo365Integration(): void {
    if (this.isCogSig) {
      void this.utilService.redirectToOldAngular('o365');
    } else {
      void this.router.navigate(['/o365/sync']);
    }
  }

  /**
   * Redirect to install signature guide on different tab
   * @param email - Loggedin user email if exist
   * @param userId - Loggedin userId if exist
   */
  redirectToSignatureGuide(email?: string, userId?: string): void {
    if (!userId)
      void this.alert.defaultInfoMessage(
        this.translate.translate('html.param.no_user_available_to_install_sig', {
          value: email
        }),
        this.translate.translate('ups')
      );
    else {
      this.employeeService.getEmployeeDefaultSignatures(userId).subscribe(result => {
        const link = `${environment.webpage}/endusercockpit?&ac=${result.activationCode || ''}&eid=${result.id}`;
        window.open(link, '_blank');
      });
    }
  }

  /**
   * Opens the campaign creation modal
   */
  openCreateCampaign(): void {
    if (this.isCogSig) {
      void this.router.navigate(['/campaigns/create/sender']);
    } else {
      let link = '';
      if (this.translate.getActiveLang() === 'en')
        link = 'https://help.mailtastic.com/en/articles/2669049-what-are-campaigns';
      else if (this.translate.getActiveLang() === 'de')
        link = 'https://help.mailtastic.com/de/articles/2669049-was-sind-kampagnen';

      const modalInputData = {
        title: 'what_kind_of_campaign',
        textSender: 'sender_based_info',
        textTarget: 'target_group_based_info',
        linkText: 'about_campaign_types',
        routeSender: '/campaigns/create/sender/',
        routeTarget: '/campaigns/create/target/',
        link
      };
      this.claimsService
        .validateClaim('Campaign:Manage')
        .pipe(tap(() => this.modalService.openCreateEventOrCampaignModal(EDataType.CAMPAIGN, modalInputData)))
        .subscribe();
    }
  }

  /**
   * Opens the event creation modal
   *
   * Redirects to '/events/create/sender' when on CogSig
   */
  openCreateEvent(): void {
    if (this.isCogSig) void this.router.navigate(['/events/create/sender']);
    else {
      const modalInputData = {
        title: 'what_kind_of_event',
        textSender: 'info_senderbased_event',
        textTarget: 'info_targetgroupbased_event',
        linkText: 'different_event_types',
        routeSender: '/events/create/sender/',
        routeTarget: '/events/create/target/',
        link: `https://help.mailtastic.com/${this.translate.getActiveLang()}/articles/2669046-events`
      };

      this.claimsService
        .validateClaim('Event:Manage')
        .pipe(tap(() => this.modalService.openCreateEventOrCampaignModal(EDataType.EVENT, modalInputData)))
        .subscribe();
    }
  }
}
